import axios from 'axios';

import createHttpRequest from '../helper/httpRequest';

const wingHttpHandleEmpty = axios.create({
    baseURL: 'http://game.huanghui.xyz/'
});

// No additional actions
export const httpRequestEmpty = createHttpRequest(wingHttpHandleEmpty);
